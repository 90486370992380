.h1 {
  color: #000;
  justify-content: center;
  margin-top: 50px;
  display: flex;
}

.list {
  justify-content: center;
  justify-self: center;
  justify-items: center;
  align-items: center;
  align-self: center;
  align-content: center;
  display: flex;
  background-color: aliceblue;
}

.gridComponent {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 100px;
}

.subGridComponent {
  width: 60vw;
}

.pageFooter {
  display: flex;
  position: fixed;
  justify-content: flex-end;
  left: 0;
  bottom: 0;
  right: 0;
}
