.box {
  width: 175px;
  height: 225px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  filter: drop-shadow(1px 1px 8px #d6d6d6);
}

.box a {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
}

.box h2 {
  font-size: 18px;
  text-align: center;
  display: flex;
  word-break: break-all;
}

.avatar {
  align-self: center;
  margin-top: 10px;
  display: flex;
}

.componentFooter {
  display: flex;
  position: absolute;
  justify-content: center;
  left: 0;
  bottom: 0;
  right: 0;
}

.componentFooter span {
  font-size: 14px;
  font-weight: 400;
}

.toolTipComponent {
  flex-direction: column;
  display: flex;
}

.toolTipA {
  font-size: 13px;
  cursor: pointer;
}

.toolTipA:hover {
  font-weight: bold;
}

.upperBox {
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  display: flex;
}
