.mainDiv {
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}

.mainDiv span {
  font-weight: 500;
  font-size: 18px;
  align-self: center;
}

.textPatternFullWidth {
  width: 100%;
}

.textPattern {
  width: 48%;
}

.sideToSideField {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  .mainDiv {
    width: 100%;
    filter: drop-shadow(1px 1px 5px #d6d6d6);
    display: flex;
    justify-content: center;
    border-radius: 15px;
    flex-direction: column;
  }

  .textPatternFullWidth {
    width: 100vw;
  }

  .textPattern {
    width: 42vw;
  }

  .sideToSideField {
    margin: 10px;
    display: flex;
  }
}
