/* .container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 40vw;
  height: 70vh;
  filter: drop-shadow(1px 1px 10px #d6d6d6);
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
} */

.container {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .containerDiv {
  width: 25vw;
  height: 60vh;
  filter: drop-shadow(1px 1px 5px #d6d6d6);
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
} */

.containerDiv {
  filter: drop-shadow(1px 1px 5px #d6d6d6);
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  width: 25vw;
  height: 70vh;
}

/* @media only screen and (min-width: 700px) {
  .containerDiv {
    width: 25vw;
    height: 70vh;
  }
} */

@media only screen and (max-width: 1000px) {
  .containerDiv {
    width: 70vw;
    height: 80vh;
  }
}

.fields {
  display: flex;
  flex-direction: column;
}

.image {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  border-radius: 60px;
  padding: 60px 100px 60px 100px;
}

.footer {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer span {
  font-size: 15px;
}
