.profile-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: aliceblue;
  display: flex;
  position: relative;
  cursor: pointer;
}

.container {
  width: 100px;
  position: relative;
  display: flex;
  justify-content: center;
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #1e1f1f;
  cursor: pointer;
  border: 1px;
  border-radius: 3px 0px 3px 0px;
}

.menu div {
  display: flex;
  justify-content: center;
  opacity: 1;
}

.menu div:hover {
  transition: all 0.45s;
  background-color: rgba(0, 0, 255, 0.582);
}

.menu div span {
  color: white;
  text-transform: uppercase;
}
