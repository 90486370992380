/* .container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 40vw;
  height: 70vh;
  filter: drop-shadow(1px 1px 10px #d6d6d6);
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
} */

@media only screen and (min-width: 1001px) {
  .container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(14, 0, 91, 0.952);
  }

  .containerDiv {
    width: 60vw;
    height: 80vh;
    filter: drop-shadow(1px 1px 5px #d6d6d6);
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    border-radius: 30px;
  }

  .imageContainer {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
  }

  .imageSubContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .img {
    margin-top: 60px;
    width: 8vw;
  }
}

.blockContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(14, 0, 91, 0.952);
  }

  .containerDiv {
    width: 95vw;
    height: 90vh;
    filter: drop-shadow(1px 1px 5px #d6d6d6);
    display: flex;
    background-color: rgb(255, 255, 255);
    align-items: center;
    border-radius: 15px;
    flex-direction: column;
  }

  .imageContainer {
    width: 40vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .imageSubContainer {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    width: 100px;
  }
}

.roundedButton {
  width: 60px;
  height: 60px;
  background-color: #e0e0e0;
  border-radius: 30%;
  filter: drop-shadow(1px 1px 5px #d6d6d6);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.iconStep {
  font-size: 45px;
}

.registerFooter {
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.roundedTest {
  position: fixed;
  bottom: 0;
  height: 100px;
  display: flex;
  flex-direction: row;
}

.roundedTest .roundedButton {
  width: 0;
  overflow: hidden;
}

.roundedTest .confirmRegister {
  height: 40px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 25vw;
}

.registerFooter .buttonConfirm {
  display: none;
}

.roundedTest .buttonConfirm {
  display: flex 2s;
  height: 40px;
  width: 150px;
  border-radius: 60px;
  background-color: rgb(50, 139, 2);
  transition: 1s;
}

.roundedTest .buttonConfirm:hover {
  background-color: rgb(50, 139, 2);
  transform: scale(1.1);
  transition: 1s;
}

.roundedTest .buttonConfirm span {
  font-weight: 500;
  color: white;
}
