.doc {
  width: 210mm;
  height: 297mm;
  filter: drop-shadow(1px 1px 10px #d6d6d6);
  background-color: white;
}

.body {
  height: 100vh;
  padding-bottom: 100px;
  padding-top: 100px;
  background-color: #d6d6d6;
  display: flex;
  justify-content: center;
}
